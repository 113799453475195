<template>
  <div class="table">
    <div class="th title flex-sb-center">
      <span>Payment</span>
      <span
        v-if="isEdit"
        class="hand fw-400"
        @click="handleReSet">Edit</span>
    </div>
    <div
      class="tr">
      <span>Method</span>
      <span>{{form.method}}
      </span>
    </div>
    <div v-if="form.type==4">
      <div
        class="tr">
        <span>Bank No.</span>
        <span>{{getOptionName('globalOptions.banks',form.bank_no_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Account No.</span>
        <span>{{form.account_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Receipt</span>
        <span
          v-if="receipt_file"
          class="theme-second-blue hand"
          @click="openFile(receipt_file)"
        >{{receipt_file.client_name}}</span>
      </div>
    </div>

    <div v-if="form.type==2">
      <div
        class="tr">
        <span>Cheque No.</span>
        <span>{{form.cheque_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Cheque</span>
        <span>{{cheque_file.client_name}}
        </span>
      </div>
      <div
        class="tr">
        <span>Cheque Owner</span>
        <span>{{form.cheque_owner}}
        </span>
      </div>
    </div>
    <div
      class="tr">
      <span>Handler Name</span>
      <span>{{form.handle_name}}
      </span>
    </div>
    <div
      class="tr">
      <span>Total</span>
      <span>{{form.amount_received|formatMoney(2,true)}}
      </span>
    </div>
    <div
      class="tr">
      <span>Status</span>
      <span>{{form.status_language}}
      </span>
    </div>
    <div
      class="tr">
      <span>Payment date </span>
      <span>{{showHkDate(form.payment_date)}}
      </span>
    </div>
    <div
      v-if="form.settle_date"
      class="tr">
      <span>Settle Payment</span>
      <span>{{showHKTime(form.settle_date)}}
      </span>
    </div>
    <div
      v-if="form.type==2"
      class="tr">
      <span>Cheque</span>
      <span
        v-if="cheque_file"
        class="theme-second-blue hand"
        @click="openFile(cheque_file)"
      >{{cheque_file.client_name}}</span>
    </div>

  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'paymentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isEdit:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    cheque_file(){
      return this.form.cheque_file||{}
    },
    receipt_file(){
      return this.form.receipt_file||{}
    }
  },
  methods:{
    handleReSet(){
      this.$emit('update:reset_payment',true)
    }
  }
}
</script>

<style scoped lang="scss">
.title{
  background: #076081!important;
  width: 100%;
  color: #fff!important;
}
</style>
